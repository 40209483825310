import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(page = 1, limit = 10, isNull = false) {
		console.log('getAll', page, limit)
		let params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: limit,
			'filters[0][field]': 'accompaniment',
			'filters[0][operator]': '=',
			'filters[0][value]': 0,
			page: page,
		}

		if (isNull) {
			params = {
				order: 'desc',
				order_by: 'name',
				offset: 0,
				limit: limit,
				'filters[0][field]': 'accompaniment',
				'filters[0][operator]': '=',
				'filters[0][value]': 0,
				'filters[1][field]': 'variant',
				'filters[1][operator]': 'IS_NULL',
				'filters[1][value]': 'null',
				page: page,
			}
		}

		const { data } = await useJwt.get(API_ROUTES.dishe.get, params)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.dishe.find}/${id}`)

		return data
	},
	save(id, data) {
		return useJwt.post(`${API_ROUTES.dishe.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.dishe.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.post(`${API_ROUTES.dishe.update}/${id}`, data)
	},
}
